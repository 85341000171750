<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.select_order") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div>
        <v-simple-table fixed-header class="table-padding-2">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.customer") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.tracking") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.customer_order_id") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.delivery_company") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.delivery_order_id") }}
                </th>
                <th role="columnheader" class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in orders" :key="order.id" class="text-center">
                <td>{{ order.company_name }}</td>
                <td><OrderTracking :tracking-id="order.tracking_id" /></td>
                <td>{{ order.customer_order_id }}</td>
                <td>{{ order.delivery_company }}</td>
                <td><DeliveryTracking :order="order" /></td>
                <td>
                  <v-btn color="info" text small @click="selectOrder(order.id)"
                    >Chọn</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatDateTime, formatNumber } from "@/libs/helpers";

export default {
  name: "SelectOrderForDetail",
  components: {
    OrderTracking: () => import("@/components/common/OrderTracking"),
    DeliveryTracking: () => import("@/components/common/DeliveryTracking"),
  },
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    items: [],
  }),
  mounted() {},
  methods: {
    formatDateTime,
    formatNumber,
    cancel() {
      this.$emit("cancel");
    },
    selectOrder(id) {
      this.$emit("selectOrder", id);
      this.cancel();
    },
  },
};
</script>

<style scoped></style>
